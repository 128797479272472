import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Custom Review Request Email Templates" prev={{
  name: 'Review Request Templates',
  path: '/review-request-templates'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Custom review request email templates allow you to request reviews using your own words.`}</p>
    <p>{`Your personalized touch could be the key to a higher conversion rate than what the email templates provided by Simple RM deliver.`}</p>
    <p>{`Like everything else in Simple RM custom review request email templates are quick to setup.`}</p>
    <p>{`When using them you still benefit from all the other Simple RM functionality you know and love, like smart send times, autosend and A/B testing.`}</p>
    <h1 {...{
      "id": "create-a-custom-template"
    }}>{`Create a Custom Template`}</h1>
    <p>{`Custom email templates are configured on the general tab of the `}<a target="_blank" href='https://app.simplereviewmanagement.com/settings'>{`settings page`}</a>{`.`}</p>
    <p>{`When you land on the page choose the review request type you want to create a review request for and click “Add Text”.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
    You can create one custom email template for each review request type.
    </Message>
    <p>{`You will see your custom template starts in the “Edit mode” state. This means you can edit, save and preview your template without it being sent to customers.`}</p>
    <p>{`Only move your template to the “Active” state when it is ready for customers to see it.`}</p>
    <p>{`To begin preparing your template for customers, enter your desired text and click save. Doing so will enabled the preview button. Click it to preview your text in the template.`}</p>
    <p>{`Repeat the process of editing, saving and previewing your text as many times as neccessary.`}</p>
    <p>{`When writing your email content keep in the mind the context of the review request type. For example you would not want to mention a review in your text for a Cautious Review Request. You can refer to the “Template context” note to refresh your memory for each type.`}</p>
    <p>{`You can include `}<a parentName="p" {...{
        "href": "/help-center/custom-review-request-email-templates#template-variables"
      }}>{`template variables`}</a>{` in your text to handle things like customer names. Read more about template variables in the section below.`}</p>
    <p>{`You can also choose how your template is used. Read more about that in the `}<a parentName="p" {...{
        "href": "/help-center/custom-review-request-email-templates#template-usage"
      }}>{`Template Usage`}</a>{` section lower down in this article.`}</p>
    <p>{`If you would like your email template reviewed by a member of the Simple RM team before you start sending it to customers feel free to `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`contact us`}</a>{`.`}</p>
    <p>{`When you are happy with your template click the switch to move your template from “Edit mode” to the “Active” state. Your template will now be sent to customers.`}</p>
    <p>{`You can now repeat this process for other review request types if you wish.`}</p>
    <div id='template-variables'></div>
    <h1 {...{
      "id": "template-variables"
    }}>{`Template Variables`}</h1>
    <p>{`Template variables are pieces of text you include in your template that get replaced with a dynamic value when Simple RM is sending the email.`}</p>
    <p><inlineCode parentName="p">{`{{customer_first_name}}`}</inlineCode>{` is a great example. If you include this variable in your template it will be replaced with the customers name in the email that is sent.`}</p>
    <p>{`As an example, the first line of your email could be:`}</p>
    <pre><code parentName="pre" {...{}}>{`{{greeting_for_time_of_day}} {{customer_first_name}},
`}</code></pre>
    <p>{`For an email sent to a customer named Mary in the afternoon it would show as:`}</p>
    <pre><code parentName="pre" {...{}}>{`Good afternoon Mary,
`}</code></pre>
    <p>{`Note, the above only works if your settings are configured to only send a review request if the customer has a first name.`}</p>
    <p>{`If your account is set to send requests to customers that do not have a first name set you will have to account for the possibility the {{customer_first_name}} variable is not replaced by anything.`}</p>
    <p>{`For a greeting you can do this:`}</p>
    <pre><code parentName="pre" {...{}}>{`{{greeting_for_time_of_day}}{{space_if_customer_has_first_name}}{{customer_first_name}},
`}</code></pre>
    <p>{`For an email sent to a customer named Mary in the afternoon it would look the same as before, but for an email sent to a customer with no first name it would show as just:`}</p>
    <pre><code parentName="pre" {...{}}>{`Good afternoon,
`}</code></pre>
    <p>{`The key difference is there is no space between the word “afternoon” and the comma.`}</p>
    <p>{`Here are the other avaliable template variables avaliable`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{{customer_first_name}}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Replaced with the first name of the customer receiving the email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{{company_name}}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The value you entered for the Simple RM company name setting.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{{greeting_for_time_of_day}}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The appropriate time of day greeting when the email is being sent. Ex. “Good afternoon”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{{space_if_customer_has_first_name}}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the customer has a first name set this variable is replaced by a space. If the customer does not have first name this variable is replaced by nothing.`}</td>
        </tr>
      </tbody>
    </table>
    <div id='template-usage'></div>
    <h1 {...{
      "id": "template-usage"
    }}>{`Template Usage`}</h1>
    <p>{`You have the ability to specify that your custom template is always used.`}</p>
    <p>{`However, you also have the option to let the system test your email template and use performance metrics to determine how often it is used in comparison with the email templates provided by Simple RM.`}</p>
    <p>{`It is recommended you let the system determine how often your custom template is sent becuase it will ensure that the template with the higher conversion rate is used.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      